import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";

export default function Gyg() {

  const data = useStaticQuery(graphql`{
  header: file(relativePath: {eq: "gyg-header.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  screens: file(relativePath: {eq: "gyg-screens.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  shop: file(relativePath: {eq: "gyg-shop.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  kiosks: file(relativePath: {eq: "guzman_kiosks.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`)




  return (
    <Layout>
      <SEO title="Referencie" />
      
      <GatsbyImage
        image={data.header.childImageSharp.gatsbyImageData}
        alt="Hlavička s logom" />  
      <GatsbyImage
        image={data.screens.childImageSharp.gatsbyImageData}
        alt="Softvér na mieru" />  
      <div className="section-pd" style={{backgroundColor:"#fcce20", color:"#000"}}>
        <div className="container" style={{textAlign:"center"}}>
        <h1>Objednávacie kiosky v GYG reštauráciách</h1>
        GYG je jedinečný austrálsky reštauračný reťazec špecializujúci sa na pokrmy, ako sú burritá, hranolky, nachos, tacos, quesadilly a ďalšie špeciality s viac ako 120 otvorenými reštauráciami.
        </div>
        
      </div>
      <div className="section-pd section-pd-bottom" style={{backgroundColor:"#fcce20", color:"#000"}}>
        <div className="container">
        <GatsbyImage image={data.shop.childImageSharp.gatsbyImageData} />  
        </div>
        
      </div>
      <div className="section-pd section-pd-bottom" style={{backgroundColor:"#fcce20", color:"#000", position:"relative"}}>
        <div style={{position:"absolute", bottom:"0", right:"0"}}><GatsbyImage
          image={data.kiosks.childImageSharp.gatsbyImageData}
          alt="Softvér v reštaurácii" />  </div>
        <div className="container">
        <div className="col-6">
          <h2>Kompletný dizajn, vývoj, a inštalácia viac ako 200 objednávacích kioskov.</h2>
          <p>Sme pokorne vďační a hrdí za túto príležitosť pracovať s takouto inovatívnou a rýchlo rastúcou spoločnosťou. Celý proces sme od návrhu až po konečné testovanie a inštaláciu navrhli a zrealizovali my.

</p>

        </div>
        </div>
        
      </div>



      <div className="ref-buttons">
        <AniLink className="back-to-ref" cover direction="left" to="/referencie" bg="#000" duration={1}>Späť na referencie</AniLink>
        <AniLink className="next-project" cover direction="left" to="/referencie/tjl-app" bg="#000" duration={1}>Ďalší projekt</AniLink>
        
      </div>

    </Layout>
  );

}
